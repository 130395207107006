export const YEAR = 'year'
export const CATEGORY = 'category'
export const GENERAL = 'general'
export const CONTENT = 'content'

export const NAME_MENU_ITEMS = {
  [YEAR]: 'Год',
  [CATEGORY]: 'Категории'
}

export const ACTIVE_SECTION_ITEM = {
  [GENERAL]: 'Общее',
  [CONTENT]: 'Содержание'
}
